import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActionPlansDetailsComponent } from '../../../modules/private/action-plans/action-plans-details/action-plans-details.component';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
  @Input({ required: true }) type!: 'public' | 'private' | 'details';

  dialog = inject(MatDialog);

  ngOnInit(): void {
    if (this.dialog.openDialogs.length > 0) {
      const dialogGoalsDetailsOpen = this.dialog.openDialogs.some(
        (dialog) =>
          dialog.componentInstance instanceof ActionPlansDetailsComponent
      );

      if (dialogGoalsDetailsOpen) {
        this.type = 'details';
      } else {
        this.type = 'public';
      }
    }
  }
}
